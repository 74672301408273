import './App.css'
import { Route, Routes } from 'react-router-dom'
import ExhibitionsPage from './components/Pages/ExhibitionsPage'
import ArtistsPage from './components/Pages/ArtistsPage'
import ReviewPage from './components/Pages/ReviewPage'
import ShopPage from './components/Pages/ShopPage'
import MainPage from './components/Pages/MainPage.jsx'
import ArtistDetailsPage from './components/Pages/ArtistDetailsPage.jsx'
import ExhibitionDetailsPage from './components/Pages/ExhibitionDetailsPage.jsx'
import ProductDetailsPage from './components/Pages/ProductDetailsPage.jsx'
import MagazineDetailsPage from './components/Pages/MagazineDetailsPage.jsx'

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/exhibitions" element={<ExhibitionsPage />} />
      <Route path="/artists" element={<ArtistsPage />} />
      <Route path="/review" element={<ReviewPage />} />
      <Route path="/shop" element={<ShopPage />} />
      <Route path="/artists/:id" element={<ArtistDetailsPage />} />
      <Route path="/exhibitions/:id" element={<ExhibitionDetailsPage />} />
      <Route path="/shop/:id" element={<ProductDetailsPage />} />
      <Route path="/review/:id" element={<MagazineDetailsPage />} />
    </Routes>
  )
}

export default App
