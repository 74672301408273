const { createClient } = require('contentful')

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
})

const getArtists = async () => {
  const response = await client.getEntries({
    content_type: 'artist',
    order: '-sys.createdAt',
  })
  const result = response.items.map((item) => {
    const createdAtDate = new Date(item?.sys?.createdAt)

    return {
      id: item?.sys?.id,
      title: item?.fields?.title,
      body: item?.fields?.body,
      coverImageUrl: item?.fields?.images?.[0]?.fields?.file?.url,
      dateYear: createdAtDate.getFullYear(),
    }
  })
  return result
}

const getArtistById = async (id) => {
  const response = await client.getEntry(id)

  const result = {
    id: response?.sys?.id,
    title: response?.fields?.title,
    body: response?.fields?.body,
    images: response?.fields?.images?.map((image) => image?.fields?.file?.url),
  }
  return result
}

const getExhibitions = async () => {
  const response = await client.getEntries({
    content_type: 'exhibition',
    order: '-sys.createdAt',
  })
  const result = response.items.map((item) => {
    const createdAtDate = new Date(item?.sys?.createdAt)

    return {
      id: item?.sys?.id,
      title: item?.fields?.title,
      body: item?.fields?.body,
      coverImageUrl: item?.fields?.images?.[0]?.fields?.file?.url,
      dateYear: createdAtDate.getFullYear(),
    }
  })
  return result
}

const getExhibitionById = async (id) => {
  const response = await client.getEntry(id)

  const result = {
    id: response?.sys?.id,
    title: response?.fields?.title,
    body: response?.fields?.body,
    images: response?.fields?.images?.map((image) => image?.fields?.file?.url),
  }
  return result
}

const getProducts = async () => {
  const response = await client.getEntries({
    content_type: 'product',
    order: '-sys.createdAt',
  })
  const result = response.items.map((item) => {
    const createdAtDate = new Date(item?.sys?.createdAt)

    return {
      id: item?.sys?.id,
      title: item?.fields?.title,
      body: item?.fields?.body,
      coverImageUrl: item?.fields?.images?.[0]?.fields?.file?.url,
      dateYear: createdAtDate.getFullYear(),
    }
  })
  return result
}

const getProductById = async (id) => {
  const response = await client.getEntry(id)

  const result = {
    id: response?.sys?.id,
    title: response?.fields?.title,
    body: response?.fields?.body,
    images: response?.fields?.images?.map((image) => image?.fields?.file?.url),
  }
  return result
}

const getMagazines = async () => {
  const response = await client.getEntries({
    content_type: 'magazine',
    order: '-sys.createdAt',
  })
  const result = response.items.map((item) => {
    const createdAtDate = new Date(item?.sys?.createdAt)

    return {
      id: item?.sys?.id,
      title: item?.fields?.title,
      body: item?.fields?.body,
      coverImageUrl: item?.fields?.images?.[0]?.fields?.file?.url,
      dateYear: createdAtDate.getFullYear(),
    }
  })
  return result
}

const getMagazineById = async (id) => {
  const response = await client.getEntry(id)

  const result = {
    id: response?.sys?.id,
    title: response?.fields?.title,
    body: response?.fields?.body,
    images: response?.fields?.images?.map((image) => image?.fields?.file?.url),
  }
  return result
}

const getGalleryFeed = async (maxItems = 4, maxImagesPerItem = 2) => {
  const artistEntries = await client.getEntries({
    content_type: 'artist',
    order: '-sys.createdAt',
    limit: maxItems,
  })

  const exhibitionEntries = await client.getEntries({
    content_type: 'exhibition',
    order: '-sys.createdAt',
    limit: maxItems,
  })

  const productEntries = await client.getEntries({
    content_type: 'product',
    order: '-sys.createdAt',
    limit: maxItems,
  })

  const magazineEntries = await client.getEntries({
    content_type: 'magazine',
    order: '-sys.createdAt',
    limit: maxItems,
  })

  const allEntries = [
    ...artistEntries.items,
    ...exhibitionEntries.items,
    ...productEntries.items,
    ...magazineEntries.items,
  ]
    .sort((a, b) => new Date(b.sys.createdAt) - new Date(a.sys.createdAt))
    .slice(0, maxItems)

  const result = allEntries.map((entry) => ({
    id: entry?.sys?.id,
    title: entry?.fields?.title,
    contentType: entry?.sys?.contentType?.sys?.id,
    images: entry?.fields?.images
      ?.slice(0, maxImagesPerItem)
      ?.map((image) => image?.fields?.file?.url),
  }))

  return result
}

module.exports = {
  getArtists,
  getArtistById,
  getExhibitions,
  getExhibitionById,
  getProducts,
  getProductById,
  getMagazines,
  getMagazineById,
  getGalleryFeed,
}
