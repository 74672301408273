import { useMemo } from 'react'

const INDEX_TEXT_HEIGHT = 27
const GALLERY_TITLE_HEIGHT = 70

const GalleryTitle = ({ currentItemIndex, galleryItems }) => {
  const itemIndexes = useMemo(
    () => galleryItems.map((_, index) => index + 1),
    [galleryItems]
  )
  const titles = useMemo(
    () => galleryItems.map((item) => item?.title),
    [galleryItems]
  )
  const totalItems = useMemo(() => galleryItems.length, [galleryItems])

  return (
    <div className="w-full invert-color fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
      <span className="block text-[18px] mb-2 text-center">
        <div
          className={`h-[27px] flex self-center justify-center text-[18px] mb-2 text-center overflow-hidden`}
        >
          <span>{'(0'}</span>
          <div
            className="inline-block  transition-transform transition-opacity duration-500 ease-in-out"
            style={{
              transform: `translateY(-${
                INDEX_TEXT_HEIGHT * currentItemIndex
              }px)`,
            }}
          >
            {itemIndexes.map((itemIndex) => (
              <div key={itemIndex}>{itemIndex}</div>
            ))}
          </div>
          <span className="mx-1"> - </span>
          <div className="inline-block">{`0${totalItems})`}</div>
        </div>
      </span>
      <div className={`h-[70px] overflow-hidden text-center`}>
        <div
          className="transition-transform transition-opacity duration-500 ease-in-out"
          style={{
            transform: `translateY(-${
              GALLERY_TITLE_HEIGHT * currentItemIndex
            }px)`,
          }}
        >
          {titles.map((title, index) => (
            <h1 key={index} className="text-[45px]">
              {title}
            </h1>
          ))}
        </div>
      </div>
    </div>
  )
}

export default GalleryTitle
