import React, { useEffect, useState } from 'react'
import ItemCard from '../Cards/ItemCard'
import { getExhibitionById } from '../../contentful'
import { useParams, useLocation } from 'react-router-dom'

function ExhibitionDetailsPage() {
  const { id } = useParams()
  const [exhibition, setExhibition] = useState({})
  const location = useLocation()

  useEffect(() => {
    getExhibitionById(id).then((exhibitionData) =>
      setExhibition(exhibitionData)
    )
  }, [id])
  return (
    <ItemCard
      category="Exhibitions & Projects"
      details={exhibition}
      openedFromGallery={!!location.state?.openedFromGallery}
    />
  )
}

export default ExhibitionDetailsPage
