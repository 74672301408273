import React, { useEffect, useState } from 'react'
import ItemCard from '../Cards/ItemCard'
import { getArtistById } from '../../contentful'
import { useParams, useLocation } from 'react-router-dom'

function ArtistDetailsPage() {
  const { id } = useParams()
  const [artist, setArtist] = useState({})
  const location = useLocation()

  useEffect(() => {
    getArtistById(id).then((artistData) => setArtist(artistData))
  }, [id])
  return (
    <ItemCard
      category="Artists"
      details={artist}
      openedFromGallery={!!location.state?.openedFromGallery}
    />
  )
}

export default ArtistDetailsPage
