import React, { useEffect, useState } from 'react'
import ItemCard from '../Cards/ItemCard'
import { getMagazineById } from '../../contentful'
import { useParams, useLocation } from 'react-router-dom'

function MagazineDetailsPage() {
  const { id } = useParams()
  const [magazine, setMagazine] = useState({})
  const location = useLocation()

  useEffect(() => {
    getMagazineById(id).then((magazineData) => setMagazine(magazineData))
  }, [id])
  return (
    <ItemCard
      category="ACon Review"
      details={magazine}
      openedFromGallery={!!location.state?.openedFromGallery}
    />
  )
}

export default MagazineDetailsPage
