import { useState } from 'react'
import Footer from '../Footer'
import Gallery from '../Gallery'
import Header from '../Header'

function MainPage() {
  const [logoVisible, setLogoVisible] = useState(false)
  return (
    <>
      <Header hasBackBtn={false} logoVisible={logoVisible} />
      <Gallery handleLogoVisibility={setLogoVisible} />
      <Footer />
    </>
  )
}

export default MainPage
