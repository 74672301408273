import React, { useMemo } from 'react'

import './ItemCard.css'
import { NavLink, useLocation } from 'react-router-dom'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="min-h-[30px]">{children}</p>
    },
  },
}

function ItemCard({ category, details, openedFromGallery }) {
  const { body, title, images = [] } = details || {}
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const onClosePath = useMemo(() => (openedFromGallery ? '/' : `/${basePath}`))

  return (
    <section className="item-section">
      <header className="item-header">
        <div className="item-header-container">
          <h1 className="item-headline-container">
            <span className="item-headline-direction">{category}</span>
            <span className="item-headline-direction">/</span>
            <span className="item-headline-title">{title}</span>
          </h1>
          <NavLink className="section-close-link" to={onClosePath}>
            Close
          </NavLink>
        </div>
      </header>
      <article>
        <p className="item-text">
          {documentToReactComponents(body, RICHTEXT_OPTIONS)}
        </p>
        <ul className="item-pic-list">
          {images.map((image, index) => {
            return (
              <li
                key={index}
                className="item-pic-list-element"
                style={{ gridRow: `${index + 1}` }}
              >
                <img src={image} alt="item-pic-1" className="item-pic" />
              </li>
            )
          })}
        </ul>
      </article>
    </section>
  )
}

export default ItemCard
