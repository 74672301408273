import React from 'react'
import './Loader.css'

function Loader({ fullScreen }) {
  return (
    <div className={`loader-container ${fullScreen ? 'full-screen' : ''}`}>
      <div id="loading"></div>
    </div>
  )
}

export default Loader
