import React, { useEffect, useState } from 'react'
import ListCard from '../Cards/ListCard'
import Header from '../Header'
import { getMagazines } from '../../contentful'

function ReviewPage() {
  const [magazines, setMagazines] = useState([])

  useEffect(() => {
    getMagazines().then((magazinesData) => setMagazines(magazinesData))
  }, [])

  return (
    <>
      <Header hasBackBtn={true} />
      <ListCard contents={magazines} />
    </>
  )
}

export default ReviewPage
