import { React, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './Header.css'
import backBtn from '../assets/icon/back-btn.svg'
import logo from '../assets/img/logo-for-header.svg'

function Header({ hasBackBtn, logoVisible }) {
  const [navOpen, setNavOpen] = useState(false)
  const [navClosing, setNavClosing] = useState(false)

  const navOpenHandler = () => {
    if (navOpen) {
      setNavClosing(true) // Start the close animation
      setTimeout(() => {
        setNavOpen(false) // After the animation completes, hide the nav
        setNavClosing(false) // Reset the closing state
      }, 1500) // Match the CSS transition time (1.5s)
    } else {
      setNavOpen(true)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    })
  }

  return (
    <header
      className={`header ${navOpen ? 'nav-open' : ''} ${
        navClosing ? 'nav-close' : ''
      }`}
    >
      <nav className="main-nav">
        <ul className="main-nav-list">
          <li className="main-nav-list-item">
            <NavLink
              className="main-nav-list-item-link"
              to={`/exhibitions`}
              onClick={navOpenHandler}
            >
              <span>Exhibitions & Projects</span>
              <span className="main-nav-list-item-link-number">(01)</span>
            </NavLink>
          </li>
          <li className="main-nav-list-item">
            <NavLink
              className="main-nav-list-item-link"
              to={`/artists`}
              onClick={navOpenHandler}
            >
              <span>Artists</span>
              <span className="main-nav-list-item-link-number">(02)</span>
            </NavLink>
          </li>
          <li className="main-nav-list-item">
            <NavLink
              className="main-nav-list-item-link"
              to={`/review`}
              onClick={navOpenHandler}
            >
              <span>ACon Review</span>
              <span className="main-nav-list-item-link-number">(03)</span>
            </NavLink>
          </li>
          <li className="main-nav-list-item">
            <NavLink
              className="main-nav-list-item-link"
              to={`/shop`}
              onClick={navOpenHandler}
            >
              <span>Shop</span>
              <span className="main-nav-list-item-link-number">(04)</span>
            </NavLink>
          </li>
        </ul>
      </nav>
      {hasBackBtn ? (
        <NavLink to={`/`}>
          <img className="back-btn" src={backBtn} alt="back icon" />
        </NavLink>
      ) : (
        ''
      )}

      <button
        className={`nav-btn ${navOpen ? 'color-white' : ''}`}
        onClick={navOpenHandler}
      >
        {navOpen ? 'Close' : 'Menu'}
      </button>
      {logoVisible && (
        <img className="logo" src={logo} alt="logo" onClick={scrollToTop} />
      )}
    </header>
  )
}

export default Header
