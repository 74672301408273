import React from 'react'
import './ListCard.css'
import circle from '../../assets/icon/ellipse.svg'
import { NavLink } from 'react-router-dom'

function ListCard({ contents }) {
  return (
    <ul className="list-card">
      {contents.map((content, index) => {
        return (
          <li key={index} className="list-item">
            <NavLink to={`${content.id}`}>
              <div className="content">
                <img
                  className="item-list-pic"
                  src={content.coverImageUrl}
                  alt="content"
                />
                <h3 className="item-list-title">{content.title}</h3>
                <span className="item-list-date">({content.dateYear})</span>
              </div>
            </NavLink>
            <img className="list-circle-icon" src={circle} alt="circle" />
          </li>
        )
      })}
    </ul>
  )
}

export default ListCard
