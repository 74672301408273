import { NavLink } from 'react-router-dom'

const contentTypeToRoute = {
  artist: 'artists',
  exhibition: 'exhibitions',
  product: 'shop',
  magazine: 'review',
}

const GalleryCard = ({ card }) => {
  const { id, contentType, image } = card

  return (
    <NavLink
      to={`${contentTypeToRoute[contentType]}/${id}`}
      state={{ openedFromGallery: true }}
    >
      <div
        key={id}
        className="group relative h-screen  overflow-hidden bg-neutral-200"
      >
        <img className="max-w-none h-full" src={image} alt="blog-i" />
      </div>
    </NavLink>
  )
}

export default GalleryCard
