import React, { useEffect, useState } from 'react'
import ListCard from '../Cards/ListCard'
import { getExhibitions } from '../../contentful'
import Header from '../Header'

function ExhibitionsPage() {
  const [exhibitions, setExhibitions] = useState([])

  useEffect(() => {
    getExhibitions().then((exhibitionsData) => setExhibitions(exhibitionsData))
  }, [])

  return (
    <>
      <Header hasBackBtn={true} />
      <ListCard contents={exhibitions} />
    </>
  )
}

export default ExhibitionsPage
