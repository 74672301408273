import GalleryCard from './GalleryCard'
import './Gallery.css'
import { motion, useTransform, useScroll } from 'framer-motion'
import { useRef, useLayoutEffect, useState, useEffect, useMemo } from 'react'
import { getGalleryFeed } from '../contentful'
import Loader from './Loader'
import GalleryTitle from './GalleryTitle'
import video from '../assets/img/video.mp4'

const Gallery = ({ handleLogoVisibility }) => {
  const targetRef = useRef(null)
  const cardsRef = useRef([])
  const [galleryItems, setGalleryItems] = useState([])
  const [carouselWidth, setCarouselWidth] = useState(0)
  const [currentItemIndex, setCurrentItemIndex] = useState(null)
  const [loading, setLoading] = useState(true)

  const galleryItemsForDisplay = useMemo(() => {
    const itemsForDisplay = []
    galleryItems.forEach((galleryItem, index) => {
      galleryItem.images.forEach((image) => {
        itemsForDisplay.push({
          id: galleryItem.id,
          itemIndex: index,
          contentType: galleryItem.contentType,
          image,
        })
      })
    })
    return itemsForDisplay
  }, [galleryItems])

  const calculateCarouselWidth = () => {
    if (cardsRef.current.length > 0) {
      const totalWidth = cardsRef.current.reduce((acc, card) => {
        if (card) {
          return acc + card.offsetWidth // Add each card's width
        }
        return acc
      }, 0)
      setCarouselWidth(totalWidth)
    }
  }

  useLayoutEffect(() => {
    calculateCarouselWidth() // Initial calculation

    // Recalculate when window resizes
    const handleResize = () => {
      calculateCarouselWidth()
    }

    window.addEventListener('resize', handleResize)

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Recalculate carouselWidth after images are fully loaded
  useEffect(() => {
    const handleImagesLoaded = () => {
      calculateCarouselWidth()
    }

    const images = Array.from(document.images)
    if (images.length > 0) {
      images.forEach((img) => {
        if (img.complete) {
          handleImagesLoaded()
        } else {
          img.addEventListener('load', handleImagesLoaded)
        }
      })
    }
  }, [cardsRef, galleryItemsForDisplay])

  const { scrollYProgress } = useScroll({
    target: targetRef,
  })

  const x = useTransform(
    scrollYProgress,
    [0, 0.67],
    ['0%', `-${carouselWidth - 1}px`]
  )

  useEffect(() => {
    getGalleryFeed()
      .then((galleryFeedResult) => setGalleryItems(galleryFeedResult))
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // Update the blog title based on the current scroll position
  useEffect(() => {
    const handleScroll = () => {
      const windowWidth = window.innerWidth

      let newIndex = null

      // Check if the first card is near the center (show title earlier)
      if (cardsRef.current[0]) {
        const firstCardLeft = cardsRef.current[0].getBoundingClientRect().left
        const firstCardRight = cardsRef.current[0].getBoundingClientRect().right

        if (firstCardLeft < windowWidth / 2 && firstCardRight > 0) {
          newIndex = 0
        }
      }

      // Check each card's visibility
      cardsRef.current.forEach((card, index) => {
        if (card) {
          const cardLeft = card.getBoundingClientRect().left
          const cardRight = card.getBoundingClientRect().right

          // Set title for cards that are in the center
          if (cardLeft <= windowWidth / 2 && cardRight >= windowWidth / 2) {
            newIndex = galleryItemsForDisplay[index].itemIndex
          }
        }
      })

      // Hide title when last card is out of view
      if (cardsRef.current.length > 0) {
        const lastCard = cardsRef.current[cardsRef.current.length - 1]
        const lastCardRight = lastCard.getBoundingClientRect().right

        if (lastCardRight / 2 - 3 <= windowWidth / 2) {
          newIndex = null
        }
      }
      setCurrentItemIndex(newIndex)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [galleryItemsForDisplay])

  useEffect(() => {
    if (currentItemIndex == null) {
      handleLogoVisibility(false)
    } else {
      handleLogoVisibility(true)
    }
  }, [currentItemIndex])

  const titleVisible = useMemo(
    () => currentItemIndex != null,
    [currentItemIndex]
  )

  if (loading) {
    return <Loader fullScreen />
  }

  return (
    <section ref={targetRef} className="relative h-[300vh]">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <motion.div
          style={{ x }}
          className="flex"
          transition={{
            duration: 10, // Adjust the duration for slower scrolling
            ease: 'easeInOut', // Customize easing for smoother transitions
          }}
        >
          <div className="w-screen h-screen flex justify-center items-center bg-white ">
            {/* <AnimatedLogo /> */}

            <video
              className="mainLogo"
              preload="auto"
              playsInline
              autoPlay
              muted
            >
              <source src={video} type="video/mp4" />
            </video>
          </div>

          {galleryItemsForDisplay.map((item, index) => {
            return (
              <div ref={(el) => (cardsRef.current[index] = el)} key={index}>
                <GalleryCard card={item} />
              </div>
            )
          })}
        </motion.div>

        {titleVisible && (
          <GalleryTitle
            currentItemIndex={currentItemIndex}
            galleryItems={galleryItems}
          />
        )}
      </div>
    </section>
  )
}

export default Gallery
