import React, { useEffect, useState } from 'react'
import ItemCard from '../Cards/ItemCard'
import { getProductById } from '../../contentful'
import { useParams, useLocation } from 'react-router-dom'

function ProductDetailsPage() {
  const { id } = useParams()
  const [product, setProduct] = useState({})
  const location = useLocation()

  useEffect(() => {
    getProductById(id).then((productData) => setProduct(productData))
  }, [id])
  return (
    <ItemCard
      category="Shop"
      details={product}
      openedFromGallery={!!location.state?.openedFromGallery}
    />
  )
}

export default ProductDetailsPage
