import React, { useEffect, useState } from 'react'
import ListCard from '../Cards/ListCard'
import Header from '../Header'
import { getProducts } from '../../contentful'

function ShopPage() {
  const [products, setProducts] = useState([])

  useEffect(() => {
    getProducts().then((productsData) => setProducts(productsData))
  }, [])

  return (
    <>
      <Header hasBackBtn={true} />
      <ListCard contents={products} />
    </>
  )
}

export default ShopPage
