import React from 'react'
import './Footer.css'
import logoWhite from '../assets/img/logo-white.svg'

function Footer() {
  return (
    <footer className="w-screen h-screen bg-black flex flex-col justify-between overflow-hidden relative">
      <address className="flex  pt-[60px] pl-[30px] pr-[30px] footer-address">
        <ul className="flex gap-[143px] address-list">
          <li className="address-list-item">
            <p className="text-gray-500">Address :</p>
            <span className="text-white">
              Giorgi Tsereteli Exit 4, Vake, <br></br> Tbilisi, Georgia. Postal
              code 0179.
            </span>
          </li>
          <li className="address-list-item">
            <p className="text-gray-500">Contact :</p>
            <a
              className="text-white"
              href="mailto:artconferencetbilisi@icloud.com"
            >
              artconferencetbilisi@icloud.com
            </a>

            <a className="text-white block" href="tel:+995595043190">
              +995 595 04 31 90
            </a>
          </li>
          <li className="address-list-item">
            <p className="text-gray-500">Socials :</p>
            <a
              className="text-white block"
              href="https://www.instagram.com/artconferencetbilisi/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              className="text-white block"
              href="https://www.facebook.com/profile.php?id=61566028077299"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            <a
              className="text-white block"
              href="https://www.tiktok.com/@artconferencetbil?_t=8pzHZf62WCl&_r=1"
              target="_blank"
              rel="noreferrer"
            >
              Tiktok
            </a>
          </li>
        </ul>
      </address>
      <div className="footer-logo">
        <img src={logoWhite} alt="logo white" />
      </div>
    </footer>
  )
}

export default Footer
