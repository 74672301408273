import React, { useEffect, useState } from 'react'
import ListCard from '../Cards/ListCard'
import { getArtists } from '../../contentful'
import Header from '../Header'

function ArtistsPage() {
  const [artists, setArtists] = useState([])

  useEffect(() => {
    getArtists().then((artistsData) => setArtists(artistsData))
  }, [])

  return (
    <>
      <Header hasBackBtn={true} />
      <ListCard contents={artists} />
    </>
  )
}

export default ArtistsPage
